.App {

  /*background-color: green;*/
}

.form {
  text-align: center;
}

.form form{
  text-align: left;
  margin: 0 auto;
  max-width: 400px;
}

.label {
  display: block;
  font-size: larger;
  color: black;
  padding: 5px;
}

.input {
  font-size: larger;
  padding: 5px;
  margin: 2px;

}

.btn {
  color: white;
  background-color: #12CC94;
  border-radius: 5px;
  border: none;
  font-size: larger;
  display: block;
  padding: 5px;
  margin: 10px auto;
  padding: 5px 25px;
  cursor: pointer;
}

.messages {
  display: flex;
  justify-content: center;
}

.error {
  vert-align: middle;
  display: block;
  background-color: red;
  color: white;
  width: fit-content;
  /*height: 50px;*/
  padding: 0px 10px;
}

.success {
  display: block;
  background-color: lightblue;
  color: black;
  width: fit-content;
  height: 50px;
  padding: 5px;
}