._list {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 12px;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 19px;
    }
}

._item {
    position: relative;
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px) {
        &:not(:last-child)::after {
            position: absolute;
            top: 0;
            right: -9px;
            width: 1px;
            height: 100%;
            content: '';
            background-color: var(--background-color-primary);
        }
    }
}

._link {
    color: #000;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 100%;
    background-size: 0 1px;
    background-repeat: no-repeat;
    transition: color .25s ease-in-out;
    transition-property: color, background-size;
    text-decoration: none;
}

._link:hover {
    color: #13A07F;
    background-size: 100% 1px;
}
