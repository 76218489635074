._main {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 6px;
    flex-wrap: nowrap;
    flex-shrink: 0;
}

._icon {
    color: #12CC94;
}

._text {
    margin: 0;
    font-weight: 700;
}

._text span {
    font-weight: 500;
}

._text:empty {
    display: none;
}

.noRates {
    font-weight: 400;
    opacity: 0.4;
    margin: 0;
}