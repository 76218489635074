
.TimeSlot {
    cursor: pointer;

    width: 90px;
    height: 42px;
    margin-top: 2px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.TimeSlot.selected:hover,
.TimeSlot:hover {
    background-color: #12CC9425;
}

.TimeSlot.selected {
    /*border: 1px solid #424242;*/
    background-color: white;
    border: 1px solid #12CC94;
}


.MuiDateCalendar-root {
    font-family: 'Maven Pro';
}

.MuiPickersCalendarHeader-label {
    font-family: 'Maven Pro';
}

.MuiPickersDay-root {
    height: 42px;
    width: 42px;
    margin-bottom: 5px;
    font-size: 20px;
    font-family: 'Maven Pro';
    border-radius: 0;
}

.MuiPickersDay-root:hover,
.MuiPickersDay-root.Mui-selected:hover,
.MuiPickersDay-root:focus.Mui-selected
 {
    background-color: #12CC9425;
}

.MuiPickersDay-root.Mui-selected {
    height: 42px;
    width: 42px;
    margin-bottom: 5px;
    font-size: 20px;
    font-family: 'Maven Pro';
    color: black;
    background-color: white;
    border: 1px solid #12CC94;
}


.MuiPickersSlideTransition-root {
    display: block;
    position: relative;
    overflow-x: hidden;
    min-height: 250px;
}

.MuiDayCalendar-weekDayLabel {
    height: 42px;
    width: 42px;
    font-size: 13px;
    font-family: 'Maven Pro';

}

.MuiPaper-root.MuiPaper-rounded {
    border-radius: 23px;
}
