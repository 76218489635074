#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.bottomNavIcon {
  fill: none;
  stroke: #d9d9d9;
}

.bottomNavIcon.Mui-selected {
  stroke: #12CC94;
}

.bottomNavIcon .MuiBottomNavigationAction-label.Mui-selected {
  color: #7B7C7C;
}

a {
  color: #13A07F;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

main {
  position: relative;
  background-color: var(--background-color-primary);
  flex-grow: 1;
  padding-block: 16px;
}

picture {
  display: block;
}

img {
  display: block;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.search-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  grid-template-areas: "b" "a";
}

@media screen and (min-width: 768px) {
  .search-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "a b";
  }
}

.map {
  width: 100%;
  min-height: 35vh;
  height: 100%;
  max-height: 50vh;
}

@media screen and (min-width: 768px) {
  .map {
    min-height: 35vh;
    height: 100%;
    max-height: 100vh;
  }
}

.ql-size-small {
  font-size: 14px;
}

.ql-size-huge {
  font-size: 24px;
}

.ql-size-large {
  font-size: 20px;
}

.ql-font-serif {
  font-family: serif;
}

.ql-font-monospace {
  font-family: monospace;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

.swiper.swiper-horizontal > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
  bottom: -1px;
}