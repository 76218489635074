._main {
    padding-block: 26px;
    margin-top: auto;
}

._wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
}

@media screen and (min-width: 768px) {
    ._wrapper {
        flex-direction: row;
        justify-content: space-between;
    }
}

._content {
    flex-grow: 1;
    display: flex;
    gap: 24px;
    flex-direction: column-reverse;

    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
}

._copyright {
    margin: 0;
}

._links {
    @media screen and (min-width: 768px) {
        flex-grow: 1;
        display: flex;
        justify-content: center;
    }
}

._version {
    font-size:x-small;
    display: block;
}
