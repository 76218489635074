._primary {
    --background-color: var(--button-white-background);
    --button-border-color: var(--button-white-background);
    --color: var(--button-white-text-color);

    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
    color: var(--color);
    text-decoration: none;
    border-radius: 16px;
    border: 1px solid var(--button-border-color);
    background-color: var(--background-color);
    transition-property: box-shadow, background-color, border-color, color;
    transition-duration: .25s;
    transition-timing-function: ease-in-out;
    cursor: pointer;
}

._primary:hover {
    --background-color: var(--button-white-background-hover);
    --button-border-color: var(--button-white-background-hover);
    --color: var(--button-white-text-color-hover);

    box-shadow: var(--button-box-shadow-hover);
}

._primary:focus {
    --background-color: var(--button-white-background-focus);
    --button-border-color: var(--button-white-background-focus);
    --color: var(--button-white-text-color-hover);

    box-shadow: none;
}

.__bordered {
    --button-border-color: rgba(0, 0, 0, .1)
}

.__filled {
    --background-color: var(--button-green-background);
    --button-border-color: var(--button-green-background);
    --color: var(--button-green-text-color);
}

.__filled:hover {
    --background-color: var(--button-green-background-hover);
    --button-border-color: var(--button-green-background-hover);
    --color: var(--button-green-text-color);
}

.__filled:focus {
    --background-color: var(--button-green-background-focus);
    --button-border-color: var(--button-green-background-focus);
    --color: var(--button-green-text-color);
}

.__shadow {
    box-shadow: var(--button-box-shadow-hover);
}

.__fluid {
    width: 100%;
}

.__link {
    color: #000;
    font-weight: 400;
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 100%;
    background-size: 0 1px;
    background-repeat: no-repeat;
    transition: color .25s ease-in-out;
    transition-property: color, background-size;
    text-decoration: none;
    font-family: 'Maven Pro', sans-serif;

    &:hover {
        box-shadow: none;
        color: #13A07F;
        background-size: 100% 1px;
    }
}

.__error {
    &:hover {
        color: #fff;
        background-color: #F87272;
        border-color: #F87272;
    }
}

.__tag {
    white-space: nowrap;
    padding: 4px 8px;
    font-size: 13px;
    gap: 4px;
    color: #A3A3A3;
    font-weight: 500;

    &:hover {
        color: #fff;
    }
}