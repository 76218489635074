.wrapper {
    height: 100%;
    display: grid;
    gap: 4rem;
    justify-items: center;
    align-content: center;

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
        &::after {
            position: absolute;
            display: block;
            bottom: -2rem;
            left: 0;
            width: 100%;
            height: 2px;
            background: #d0d0d0;
            content: '';
        }

        @media screen and (min-width: 768px) {
            &::after {
                right: -2rem;
                bottom: 0;
                left: unset;
                width: 2px;
                height: 100%;
            }
        }
    }
}

.content {
    width: 100%;
    max-width: 420px;
}

.paymentForm {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    background: #fff;
    border-radius: 13px;
    border: 1px solid #D9D9D9;
}

.buttonPayment {
    cursor: pointer;
    margin: 0;
    background-color: rgb(18, 204, 148);
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 13px;
    font-size: 19px;
    width: 100%;
    color: #FFF;
    font-family: "Maven Pro", sans-serif;
    font-weight: 700;
}