._wrapper {
    width: 100%;
    max-width: 480px;
    display: flex;
    justify-content: flex-end;
}

._desktop {
    display: none;
}

@media screen and (min-width: 768px) {
    ._desktop {
        display: block;
        width: 100%;
    }
}

._open_form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px 4px 4px 1em;
    font-size: 14px;
    border-radius: 32px;
    background-color: #fff;
    cursor: pointer;
    border: 1px solid #D7D7D7;
}

@media screen and (min-width: 768px) {
    ._open_form {
        display: none;
    }
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--button-green-background);
    cursor: pointer;
    color: var(--button-green-text-color);
}

.text {
    opacity: .7;
}

._submit {
    width: 32px;
    height: 32px;
    padding: 0;
    border-radius: 50%;
    border: none;
    background-color: var(--button-green-background);
    cursor: pointer;
    color: var(--button-green-text-color);
    transition-property: box-shadow, background-color;
    transition-duration: .25s;
    transition-timing-function: ease-in-out;
}

._submit:hover {
    background-color: var(--button-green-background-hover);
    box-shadow: var(--button-box-shadow-hover);
}

._submit:focus  {
    background-color: var(--button-green-background-focus);
}

._form_container {}

._main {
    position: relative;
    width: 100%;
}

._label {
    display: block;
    width: 100%;
}

._input {
    width: 100%;
    padding: 12px 44px 12px 16px;
    border-radius: 40px;
    border: 1px solid #D7D7D7;
    font-weight: 700;
    font-size: 14px;
    transition: outline .25s ease-in-out;
}

._input:focus {
    outline: 1px solid #12CC94;
}

._input::placeholder {
    font-weight: 400;
}

._submit {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
}

._modal_container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background: rgba(255, 255, 255, 0.95);
}

._modal_container form {
    max-width: 550px;
}

._modal_container form label input {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
}

._close_modal {
    position: absolute;
    top: 16px;
    right: 16px;
    display: block;
    width: 35px;
    height: 35px;
    padding: 0;
    margin: 0;
    line-height: 0;
    border: none;
    background-color: transparent;
}

._close_modal span {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 110%;
    height: 3px;
    background: #000;
    border-radius: 3px;
    transform: rotate(45deg);
}

._close_modal span:last-child {
    transform: rotate(-45deg);
}
