._wrapper {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*justify-content: space-between;*/
    /*gap: 32px;*/
}

._list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
    justify-self: flex-start;
}

@media screen and (min-width: 460px) {
    ._list {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (min-width: 640px) {
    ._list {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (min-width: 768px) {
    ._list {
        grid-template-columns: repeat(3, 1fr);
    }

    ._list._grid_search {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (min-width: 1280px) {
    ._list {
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
    }
}
@media screen and (min-width: 1440px) {
    ._list {
        grid-template-columns: repeat(5, 1fr);
    }

    ._list._grid_search {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media screen and (min-width: 2544px) {
    ._list {
        grid-template-columns: repeat(6, 1fr);
    }

    ._list._grid_search {
        grid-template-columns: repeat(4, 1fr);
    }
}

._item {}

._action {
    padding: 12px 24px;
    border: none;
    border-radius: 16px;
    background-color: #12CC94;
    color: #FFF;
    font-family: 'Maven Pro';
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    transition: background-color .25s ease-in-out;
    transition-property: box-shadow, background-color;
    cursor: pointer;
}

._action:hover {
    background-color: #40DAAC;
    box-shadow: var(--button-box-shadow-hover);
}

._action:focus {
    background-color: #1BB893;
    box-shadow: none;
}
