._wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
}

._content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

._title {}

._title span {
    display: block;
    font-size: 10rem;
    color: #40DAAC;
}

._action {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 12px 24px;
    font-family: inherit;
    font-size: inherit;
    font-weight: 500;
    text-decoration: none;
    text-align: center;
    border-radius: 16px;
    background-color: var(--button-green-background);
    color: var(--button-green-text-color);
    transition-property: box-shadow, background-color;
    transition-duration: .25s;
    transition-timing-function: ease-in-out;
    border: none;
    cursor: pointer;
}

._action:hover {
    background-color: var(--button-green-background-hover);
    box-shadow: var(--button-box-shadow-hover);
}

._action:focus {
    background-color: var(--button-green-background-focus);
}