.ImageGallery_1 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.ImageGallery_1 a {
    aspect-ratio: 2/1;
}

.ImageGallery_2,
.ImageGallery_4 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
}

.ImageGallery_2 > a,
.ImageGallery_2 > picture {
    aspect-ratio: 1/1;
}

.ImageGallery_3 > a:first-child,
.ImageGallery_3 > picture:first-child {
    grid-column: span 2;
    grid-row: span 2;
}
.ImageGallery_3 > a:nth-child(2),
.ImageGallery_3 > picture:nth-child(2) {
    aspect-ratio: 2/1;
    grid-column: span 2;
    grid-row: span 1;
}
.ImageGallery_3 > a:nth-child(3),
.ImageGallery_3 > picture:nth-child(3) {
    aspect-ratio: 2/1;
    grid-column: span 2;
    grid-row: span 1;
}

.ImageGallery_4 > a,
.ImageGallery_4 > picture {
    aspect-ratio: 2/1;
}

.ImageGallery_5 > a:first-child,
.ImageGallery_5 > picture:first-child {
    grid-column: span 2;
    grid-row: span 2;
}
.ImageGallery_5 > a,
.ImageGallery_5 > picture {
    aspect-ratio: 1/1;
}