.form {
  text-align: center;
}

.form_content {
  text-align: left;
  margin: 0 auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.from_grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 16px;
}

@media screen and (min-width: 768px) {
  .from_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.label {
  display: inline-block;
  font-size: larger;
  color: black;
  padding: 5px 0;
}

.input {
  font-size: larger;
  padding: 5px;
  margin: 2px;

}

.btn {
  color: white;
  background-color: #12CC94;
  border-radius: 5px;
  border: none;
  font-size: larger;
  display: block;
  margin: 10px auto;
  padding: 5px 25px;
  cursor: pointer;
  transition-property: background-color, box-shadow, color;
  transition-duration: .25s;
  transition-timing-function: ease-in-out;
}

.btn:hover,
.btn:focus {
  background-color: #40DAAC;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
}

.messages {
  display: flex;
  justify-content: center;
}

.error {
  vert-align: middle;
  display: block;
  background-color: red;
  color: white;
  width: fit-content;
  /*height: 50px;*/
  padding: 0 10px;
}

.success {
  display: block;
  background-color: lightblue;
  color: black;
  width: fit-content;
  height: 50px;
  padding: 5px;
}