._wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

._overlay {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform: scale(1.2);
    filter: blur(20px);
}

._wrapper picture {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 16px;
}

._wrapper picture img {
    position: relative;
    object-fit: contain;
}
