:root {
  --background-color-primary: #F5F5F5;
  --font-family-primary: 'Maven Pro', sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans;
  --button-white-background: #fff;
  --button-white-background-hover: #12CC94;
  --button-white-background-focus: #1BB893;
  --button-white-icon-color: #979797;
  --button-white-icon-color-hover: #fff;
  --button-white-icon-color-focus: #fff;
  --button-white-text-color: #000;
  --button-white-text-color-hover: #fff;
  --button-white-text-color-focus: #fff;
  --button-green-background: #12CC94;
  --button-green-background-hover: #40DAAC;
  --button-green-background-focus: #1BB893;
  --button-green-icon-color: #fff;
  --button-green-text-color: #fff;
  --button-box-shadow-hover: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  --button-border-color: transparent;

  --icon-color-primary: #979797;
  --icon-color-primary-hover: #12CC94;

  --color-primary: #12CC94;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-family: var(--font-family-primary);
}

body {
  margin: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: #222222;
  font-family: var(--font-family-primary);
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  --container-padding-inline: 16px;

  margin: 0 auto;
  max-width: 2384px;
  height: 100%;
  padding-inline: var(--container-padding-inline);
}

.container--tiny {
  max-width: calc(1120px + var(--container-padding-inline) * 2);
}

@media screen and (min-width: 992px) {
  .container {
    --container-padding-inline: 40px;
  }
}

@media screen and (min-width: 1440px) {
  .container {
    --container-padding-inline: 80px;
  }
}

.section {
  position: relative;
  padding-block: 32px;
}

.section__title {
  font-size: 20px;
}

@media screen and (min-width: 768px) {
  .section__title {
    font-size: 24px;
  }
}

.section__content {
  position: relative;
}

.section--border-bottom {
  border-bottom: 1px solid #D7D7D7;
}