._wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding-bottom: 10px;

    @media screen and (min-width: 768px) {
        padding-bottom: 0;
    }
}

._grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 8px;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
}

._grid:empty {
    display: none;
}

._grid a {
    display: block;
    width: 100%;
    height: 100%;
}

._modal {
    position: fixed;
    top: 0;
    left: -100vw;
    z-index: -100;
    visibility: hidden;
}

._button {
    display: inline-flex;
    padding: 10px 24px;
    align-items: center;
    gap: 8px;
    font-family: var(--font-family-primary);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    border-radius: 24px;
    border: none;
    background: var(--button-white-background);
    box-shadow: var(--button-box-shadow-hover);
    cursor: pointer;
    transition-property: background-color, color;
    transition-duration: .25s;
    transition-timing-function: ease-in-out;
}

@media screen and (min-width: 768px) {
    ._button {
        position: absolute;
        left: 8px;
        bottom: 26px;
    }
}

@media screen and (min-width: 992px) {
    ._button {
        right: 16px;
        left: unset;
    }
}

._button svg {
    stroke: var(--button-white-icon-color);
    transition: stroke .25s ease-in-out;
}

._button:hover {
    background-color: var(--button-white-background-hover);
    color: var(--button-white-text-color-hover);
}

._button:focus {
    background-color: var(--button-white-background-focus);
    color: var(--button-white-text-color-hover);
}

._button:hover svg,
._button:focus svg {
    stroke: var(--button-white-icon-color-hover);
}