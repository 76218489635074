._picture {
    overflow: hidden;
    border-collapse: collapse;
}

._image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}