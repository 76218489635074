._main {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .3);
}

._wrapper {
    position: relative;
    padding: 20px;
    width: 95%;
    max-width: 450px;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    background: #fff;
}

._heading {
    padding-right: 28px;
}

._title {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
}

._close_button {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

._content {
    height: calc(100% - 157px);
    display: flex;
    gap: 8px;
    padding-block: 16px;
    border-top: 1px solid rgba(0, 0, 0, .3);
    border-bottom: 1px solid rgba(0, 0, 0, .3);
    overflow-y: auto;
}

._content::-webkit-scrollbar {
    width: .25rem;
}

._content::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
}

._content::-webkit-scrollbar-thumb {
    background-color: rgba(18, 204, 148, 0.4);
}

._icon_wrapper {}

._text_wrapper {
    width: 100%;
}

._actions {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    flex-wrap: wrap;

    &:empty {
        display: none;
    }
}
