._card {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
    background-color: white;
    border-radius: 16px;
    color: #000;
}

._card:hover {
    color: #13A07F;
}

._button {
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    padding: 0;
    line-height: 0;
    border: none;
    background: transparent;
    cursor: pointer;
}

._button svg {
    fill: rgba(0, 0, 0, 0.3);
    stroke-width: 2px;
    stroke: #FFF;
    transition: fill .3s ease-in-out;
}

._button:hover svg {
    fill: rgba(255, 0, 0, 0.3);
}

._picture {
    position: relative;
    display: block;
    width: 100%;
    aspect-ratio: 340/336;
    border-radius: 16px;
    overflow: hidden;
}

._caption_heading {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    ._search_page ._caption_heading {
        flex-direction: column-reverse;
    }
}

._caption {
    padding-inline: 16px;
    font-size: 16px;
    transition: color .25s ease-in-out;
}

._title {
    margin: 0;
    font-size: inherit;
}

._link {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    color: currentColor;
    text-decoration: none;
}

._link::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
}

._link span {
    display: block;
    hyphens: auto;
}

._location {
    font-weight: 500;
}

._rating_wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    ._search_page ._rating_wrapper {
        align-self: flex-end;
    }
}

._price {
    margin: auto 0 0;
    padding-inline: 16px;
    padding-bottom: 20px;
    text-align: right;
    color: #000;
}


