/*._container {*/
/*    position: relative;*/
/*    text-transform: uppercase;*/
/*}*/

/*._heading {*/
/*    display: inline-flex;*/
/*    align-items: center;*/
/*    gap: 8px;*/
/*}*/

/*._heading_button {*/
/*    padding: 0;*/
/*    margin: 0;*/
/*    font-family: 'Maven Pro';*/
/*    border: none;*/
/*    background: transparent;*/
/*    font-size: 16px;*/
/*    cursor: pointer;*/
/*}*/

/*._heading_button span {*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/

/*._dropdown {*/
/*    position: absolute;*/
/*    top: 100%;*/
/*    right: 0;*/
/*    visibility: hidden;*/
/*    opacity: 0;*/
/*    transition-property: opacity, visibility;*/
/*    transition-duration: .25s;*/
/*    transition-timing-function: ease-in-out;*/
/*}*/

/*._dropdown[data-open="true"] {*/
/*    visibility: visible;*/
/*    opacity: 1;*/
/*}*/

.label {
    display: flex;
    align-items: center;
    gap: 4px;
}

.select {
    border: none;
    padding: .5rem;
    border-radius: .5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align: center;
    background-color: #F5F5F5;
    
    @media screen and (min-width: 768px) {
        text-align: left;
        background-color: unset;
    }

    &:focus-visible {
        outline: 1px solid #D7D7D7;
    }
}