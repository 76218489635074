.bookingPage {
    margin: 0 auto;
    background: white;
    padding-top: 24px;
}

.bookingData {

    display: flex;
    gap: 48px;
    justify-content: center;
}

.inputsBlock,
.checkoutBlock {
    width: 535px;
}

.checkoutBlock>div {
    border: 1px solid #D7D7D7;
    border-radius: 16px;
}

.bookButton {
    padding: 0px 24px 24px 24px;
}

.bookButton>button {
    margin: 15px 0;
    background-color: rgb(18, 204, 148);
    padding: 13px 0px;
    border: 1px solid #d9d9d9;
    border-radius: 16px;
    font-size: 16px;
    width: 100%;
    color: #FFF;
    font-weight: 500;
}

.pageTitle {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 18px;
}

.subTitle {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 16px;
}

.title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 23px;
}

.inputsBlockMobile {
    background-color: #d9d9d9;
}

.inputsBlockMobile>div,
.inputsBlock>div {
    margin-top: 0.5rem;
    padding: 0.5rem 0;
    background-color: white;
}

.inputsBlockMobile strong {
    display: block;
    padding: 10px;
}


.partyDetails {
    display: flex;
    padding: 24px 24px 0 24px;
    gap: 16px;
}

.partyPicture {
    flex: 1;
    max-width: 100px;
    border-radius: 16px;
    overflow: hidden;
}

.partyVendor {
    display: flex;
    margin-bottom: 8px;
}

.partyInfo{

}

.partyTitle {
    font-size: 20px;
    font-weight: 700;
}

.counterBlock,
.totalBlock,
.detailsBlock {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    padding: 5px 24px 8px 24px;
}



.counterBlock:nth-of-type(even) {
    background: #EBEBEB;
}

.alignRight {
    text-align: right;
    white-space: nowrap;
}

.spacer {
    border-bottom: 1px solid #d9d9d9;
    margin: 20px 24px 14px 24px;
}

.invalidText {
    color: #ff2d2d;
}
