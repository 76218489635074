.checkout {
    border-radius: 13px;
    overflow: hidden;
    border: 1px solid #D9D9D9;
    background: #fff;
}

.picture {
    height: 170px;
}

.content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title {
    font-weight: 700;
    font-size: 25px;
    margin-top: 0;
    margin-bottom: 12px;
}

.rating {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;
}

.location {
    margin: 0;
    display: flex;
    align-items: flex-start;
    gap: .25rem;

    p {
        margin: 0;
    }

    svg {
        width: 1rem;
        height: 1rem;
    }
}

.vendorName {
    margin: 0;
    position: relative;
    padding-right: 24px;
    font-weight: 600;
    font-size: 16px;
}

.details,
.counter {
    display: flex;
    flex-direction: column;
    gap: .5rem;

    p {
        margin: 0;
        display: flex;
        justify-content: space-between;
        color: #7B7C7C;

        span {
            color: #222222;
            white-space: nowrap;
        }
    }
    
    &:after {
        display: block;
        width: 100%;
        height: 1px;
        background: #D9D9D9;
        margin-block: 12px;
        content: '';
    }
}

.total {
    margin: 0;
    display: flex;
    justify-content: space-between;
    color: #7B7C7C;
    font-weight: 600;

    span {
        color: #222222;
        white-space: nowrap;
    }
}