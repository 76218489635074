._vendorBreadcrumbs {
    display: flex;
    align-items: center;
    gap: 6px;
}

._vendorBreadcrumbs_button {
    width: 38px;
    height: 38px;
    padding: 0;
    border: none;
    cursor: pointer;
    line-height: 0;
    border-radius: 50%;
    background-color: transparent;
}

._vendorBreadcrumbs_button svg {
    stroke: var(--icon-color-primary);
    transition: stroke .25s ease-in-out;
}

._vendorBreadcrumbs_button:hover svg,
._vendorBreadcrumbs_button:focus svg {
    stroke: var(--icon-color-primary-hover);
}

._vendorTitle {
    margin: 0;
    font-size: 22px;
}

@media screen and (min-width: 768px) {
    ._vendorTitle {
        font-size: 32px;
    }
}

._vendorHeading {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;
    font-size: 16px;
    margin-top: 9px;
}

@media screen and (min-width: 768px) {
    ._vendorHeading {
        flex-direction: row;
    }
}

._vendorLocationWrapper {
    display: flex;
    align-items: center;
}

._vendorLocation {
    margin: 0;
    padding-left: 24px;
    position: relative;
}

._vendorLocation::before {
    position: absolute;
    top: calc(.5em - 1px);
    left: 11px;
    display: inline-block;
    width: 4px;
    height: 4px;
    flex-shrink: 0;
    background: #000;
    border-radius: 50%;
    content: '';
}

._vendorLocation:empty::before {
    content: unset;
}

._vendorHeading_actions {
    align-self: flex-start;
    display: flex;
    gap: 17px;
}

._vendorHeading_button {
    display: inline-flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
}

._vendorHeading_button svg {
    fill: none;
    stroke: var(--icon-color-primary);
    stroke-width: 4px;
    transition: stroke .25s ease-in-out;
}

._vendorHeading_button:hover svg {
    stroke: var(--icon-color-primary-hover);
}

._vendorImages {
    margin-top: 21px;
}

._party_container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding-top: 15px;
    gap: 24px;
}

@media screen and (min-width: 768px) {
    ._party_container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 992px) {
    ._party_container {
        grid-template-columns: repeat(3, 1fr);
    }
}

._party_details {
    grid-column: span 1;
    font-size: 16px;
    font-weight: 400;
}

@media screen and (min-width: 992px) {
    ._party_details {
        grid-column: span 2;
    }
}

._party_details_heading {
    display: flex;
    flex-direction: column;
    gap: 11px;
    padding-bottom: 20px;
}

._party_title {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
}

@media screen and (min-width: 768px) {
    ._party_title {
        font-size: 24px;
    }
}

._rte {
    padding-block: 16px;
    border-top: 1px solid #D7D7D7;
}

._rte:last-child {
    border-bottom: 1px solid #D7D7D7;
}

._rte > * {
    margin-block: 12px;
}

._rte > *:first-child {
    margin-top: 0;
}

._rte > *:last-child {
    margin-bottom: 0;
}

._rte > ul {
    margin-block: 12px;
    padding-left: 40px;
    list-style-type: disc;
}