.main {
    margin: 0 auto;
    max-width: 800px;

    h1 {
        font-size: 48px;
        text-align: center;
    }

    h2 {
        position: relative;
        margin-block: 1em;
        font-size: 36px;
        text-align: center;

        &::after {
            position: absolute;
            bottom: -.5em;
            display: block;
            width: 100%;
            height: 1px;
            background-color: #D7D7D7;
            content: '';
        }
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 18px;
    }

    ol {
        list-style: none;
        counter-reset: li;
        padding-left: 20px;

        li {
            &:before {
                counter-increment: li;
                content: counters(li, ".") ". ";
            }
        }
    }

    ul {
        list-style-type: disc;
        padding-left: 2em;
    }

    section div div div {
        padding-left: 20px;
    }
}