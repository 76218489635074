.Carousel_PartyCard {
    --carousel-padding-bottom: 32px;

    padding-bottom: var(--carousel-padding-bottom);
}

.swiper-slide {
    height: unset;
    min-height: 100%;
    transition: margin-left .25s linear;
}

@media screen and (max-width: 767px) {
    .swiper-pagination {
        display: none;
    }

    .Carousel_PartyCard {
        --carousel-padding-bottom: 0px;
    }
}

@media screen and (max-width: 992px) {
    .Carousel_PartyCard {
        margin-right: calc(-100vw/2 + 100%/2);
    }

    .swiper-slide {
        padding-right: 30px;
    }

    .swiper-slide-active + .swiper-slide-next,
    .swiper-slide-active + .swiper-slide-next + .swiper-slide {
        margin-left: -30px;
    }
}

.Carousel_PartyCard__button {
    position: absolute;
    top: calc(50% - var(--carousel-padding-bottom));
    z-index: 3;
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: none;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .5);
    transition-property: box-shadow, background-color;
    transition-duration: .25s;
    transition-timing-function: ease-in-out;
    cursor: pointer;
}

.Carousel_PartyCard__button[disabled] {
    visibility: hidden;
    opacity: 0;
}

@media screen and (max-width: 767px) {
    .Carousel_PartyCard__button {
        transform: translateY(-50%);
    }
}

.Carousel_PartyCard__button svg {
    stroke: #000;
    transition: stroke .25s ease-in-out;
}

.Carousel_PartyCard__button:hover {
    background-color: #fff;
    box-shadow: var(--button-box-shadow-hover);
}

.Carousel_PartyCard__button:focus {
    background-color: #fff;
}

.Carousel_PartyCard__button:hover svg,
.Carousel_PartyCard__button:focus svg {
    stroke: var(--icon-color-primary-hover);
}

.Carousel_PartyCard__button-prev {
    left: 15px;
}

.Carousel_PartyCard__button-next {
    right: 15px;
}

.Carousel_PartyCard .swiper-pagination {
    top: calc(100% - 16px);
}

.swiper-pagination-bullet {
    opacity: 1;
    background-color: #d7d7d7;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #12CC94;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .Carousel_PartyCard::after {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        display: block;
        width: calc(40px + var(--container-padding-inline));
        height: 100%;
        background: linear-gradient(90deg, transparent, #F5F5F5 60%);
        content: '';
    }
}

@media screen and (min-width: 992px) {
    .Carousel_PartyCard::after {
        content: unset;
    }
}