.inputBlock {
  position: relative;
}

.inputBlockCountry {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;

  label,
  input,
  span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }
}

.inputBlock input[type="radio"] {
  appearance: none;
  border-radius: 50%;
  background: #FFF;
  border: 1px solid #979797;
  height: 8px;
  width: 8px;
  margin: .3em 0 0;
}

.inputBlock input[type="radio"]:checked {
  background: #12CC94;
  border: 1px solid #12CC94;
}

.partyOptions {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
}

.partyOptions>div {
  font-size: 14px;
}

._radio_label {
  display: block;
  width: fit-content;
  cursor: pointer;
  font-size: 14px;

  ._radio_wrapper {
    display: flex;
    gap: 8px;
  }

  input {
    padding: 0;
    margin: auto 0;
    flex-shrink: 0;
    cursor: pointer;
    transition: border-color .25s ease-in-out;
  }

  &:hover,
  &:focus,
  &:focus-within {
    input {
      border-color: #40DAAC;
    }
  }

  ._radio_text_wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  ._radio_title {
    font-weight: 500;
  }

  ._description {
    padding-left: 16px;
    opacity: .9;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

.partyOptionsTitle {
  margin: 0;
  color: #000;
  font-family: var(--font-family-primary);
  font-size: 16px;
  font-weight: 500;
}

.input {
  position: relative;
  width: 100%;
  display: block;
}

.inputLabel {
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  font-family: var(--font-family-primary);
}

.inputField {
  width: 100%;
  color: currentColor;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #D7D7D7;
  margin-top: 6px;
  margin-bottom: 1px;
  border-radius: 16px;
  display: block;
  font-size: 16px;
  font-family: var(--font-family-primary);

  &::placeholder {
    color: #a3a3a3;
    font-size: inherit;
  }
}

.inputField:focus {
  outline: none;
  border: 1px solid #12CC94;
}

._checkbox_text.error {
  color: #ff2d2d;
}

._checkbox_pseudo.error,
.inputField.error {
  border: 1px solid #ff2d2d;
}

.errorMessage {
  position: absolute;
  top: 100%;
  right: 0;
  text-align: right;
  font-size: small;
  color: #ff2d2d;
  white-space: nowrap;
}


._checkbox_label {
  display: block;
  gap: 8px;
  cursor: pointer;

  ._description_wrapper {
    position: static;
  }
}

._checkbox_input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

._checkbox_wrapper {
  display: flex;
  gap: 8px;
}

._checkbox_pseudo {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-bottom: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #979797;
  border-radius: 4px;
  color: transparent;
  font-size: 14px;
  font-weight: 700;
  background-color: transparent;
  transition-property: border-color, background-color, color;
  transition-duration: .25s;
  transition-timing-function: ease-in-out;
}

._checkbox_label:hover ._checkbox_pseudo {
  border-color: #40DAAC;
}

._checkbox_input:checked + span ._checkbox_pseudo {
  color: #fff;
  background-color: #40DAAC;
  border-color: #40DAAC;
}

._checkbox_pseudo_iphone {
  position: relative;
  display: block;
  width: 30px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #D7D7D7;
  transition: background-color 0.3s linear;
}

._checkbox_pseudo_iphone__circle {
  display: block;
  width: 16px;
  height: calc(100% + 2px);
  margin: -1px 0 0 -1px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #D7D7D7;
  transition: margin 0.3s linear;
}

._checkbox_input:checked + ._checkbox_wrapper ._checkbox_pseudo_iphone  {
    background-color: #12CC94;
}

._checkbox_input:checked + ._checkbox_wrapper ._checkbox_pseudo_iphone__circle {
  margin: -1px -1px 0 13px;
}


._checkbox_text {
  margin-top: -2px;
}

._textarea_wrapper {
  border: 1px solid #D7D7D7;
  margin-top: 6px;
  margin-bottom: 1px;
  border-radius: 16px;
  overflow: hidden;

  &:focus-within {
    border: 1px solid #12CC94;

    ._textarea:focus {
      outline: none;
    }
  }
}

._textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 10em;
  color: currentColor;
  padding: 16px;
  background: #FFFFFF;
  font-size: 16px;
  font-family: var(--font-family-primary);
  resize: none;
  border: none;

  &::placeholder {
    color: #a3a3a3;
    font-size: inherit;
  }

  &::-webkit-scrollbar {
    width: 10px;

  }

  &::-webkit-scrollbar-track {
    background: #f5f5f5;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #12CC94;
    border: 2px solid #f5f5f5;
    cursor: grab;
  }
}

._radio_label {}

._radio_text_wrapper {}

._radio_title {}

._radio_description {}

._description_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 4px;

  .errorMessage {
    position: static;
    margin: 0 0 0 auto;
  }
}

._description {
  font-size: 13px;
  color: #a3a3a3;
  padding-left: 6px;
}