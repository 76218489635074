._main {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #fff;
    border-bottom: 1px solid #D7D7D7;
}

._content {
    display: flex;
    padding-block: 16px;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}

._logo {}

._search {
    display: flex;
    justify-content: center;
    order: 3;
    flex-grow: 1;
}

@media screen and (min-width: 768px) {
    ._search {
        order: unset;
        flex-grow: 1;
    }
}

._cta {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 0 0 auto;
}
._cta a {
text-decoration: none;
}

@media screen and (min-width: 768px) {
    ._cta {
        gap: 16px;
    }
}
