._list {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 14px;
}

._item {
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
    gap: 2px;
    white-space: nowrap;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    line-height: 1;
}

._item span {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
}

._item span:first-child {
    font-size: 18px;
    line-height: 0;
    padding-bottom: 2px;
}