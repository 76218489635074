.tagsBar {
    --container-padding-inline: 16px;

    display: flex;
    gap: 8px;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-inline: var(--container-padding-inline);
}

@media screen and (min-width: 992px) {
    .tagsBar {
        --container-padding-inline: 40px;
    }
}

@media screen and (min-width: 1440px) {
    .tagsBar {
        --container-padding-inline: 80px;
    }
}

.tagsBar .tagsBarCarousel {
    max-width: calc(100% - 72px);

    @media screen and (min-width: 992px) {
        padding: 0 2rem;
    }
}

.tagsBarCarousel .tagsBarCarouselItem {
}

.tagsBar .tagsBarCarousel .tagsBarCarouselArrow:disabled {
    opacity: 0;
    visibility: hidden;
}

.tagsBar .tagsBarCarousel .tagsBarCarouselArrowPrev {
    left: 0;
}

.tagsBar .tagsBarCarousel .tagsBarCarouselArrowNext {
    right: 0;
}

.tagsBarLabel {
    cursor: pointer;
    font-size: 13px;
    display: flex;
    gap: 1px;
    align-items: center;
    white-space: nowrap;
    color: #a3a3a3;
    font-weight: 500;
    background-color: #fff;
    padding: 2px 8px;
    border-radius: 25px;
    transition: background-color, color, opacity 0.2s ease-in-out;
}

.tagsBarLabel:hover {
    opacity: 0.7;
}

.tagsBarInput:not(:checked) + .tagsBarLabel:hover {
    color: #515151;
}

.tagsBarCheckboxButton,
.tagsBarCheckboxButtonNext {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 1;
}

.tagsBarCheckboxButtonNext {
    left: unset;
    right: 0;
}

.tagsBarInput {}

.tagsBarInput:checked + .tagsBarLabel {
    background-color: #a3a3a3;
    color: #fff;
}


.tagsBarLabel span {
    display: flex;
    align-items: center;
}

.hash {
    font-size: 18px;
    font-weight: bold;
    transform: translate(0, -5%);
}

.tagsBarAction {
    position: relative;
    display: inline-block;
}