.bookingWidget {
    padding: 24px;
    background: #FFFFFF;
    box-shadow: var(--button-box-shadow-hover);
    border-radius: 16px;
    position: relative;
}

.bookingWidgetDisabled {
    filter: blur(2px);
    pointer-events: none;
}

._heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    padding-bottom: 15px;
}

._price {
    margin: 0;
    font-weight: bold;
}

._rating {}

._actions {
    padding-block: 20px;
}

._check {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-bottom: 20px;
}

._check p {
    display: inline-flex;
    justify-content: space-between;
    margin: 0;
}

._total {
    padding-top: 16px;
    padding-bottom: 0;
    font-weight: bold;
    border-top: 1px solid #B7B7B7;
}

.errorMessage {
    /*position: absolute;*/
    /*top: 100%;*/
    right: 0;
    text-align: right;
    font-size: small;
    color: #ff2d2d;
}
